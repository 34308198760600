import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
// import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
// import ProjectPreviewGrid from "../components/project-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import '../styles/consultations.css'
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

//TODO: add this to CMS
import * as contactData from '../components/constant/data.js';

// import Footer from '../components/Footer/footer';
import Navbar from '../components/Navbar/Navbar'
export const query = graphql`
  query ConsultationPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
   
  }
`;

const ConsultationPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
//   const projectNodes = (data || {}).blogs
//     ? mapEdgesToNodes(data.blogs)
//         .filter(filterOutDocsWithoutSlugs)
//         .filter(filterOutDocsPublishedInTheFuture)
//     : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }


  return (
    <Layout>
        <SEO title={site.title} description={site.description} keywords={site.keywords} />
        <div className="top-banner">
        <Navbar/>
<StaticImage className="banner" src='./images/6.png' alt="Consultations"/>

        </div>
<div className="top-heading">
    <h1> LET'S TALK ABOUT YOUR BRAND</h1>
</div>
<div class="consultation-content"> 
    <p>
    Interested in working with us? Fantastic.
We are a small team of dedicated creatives who’re enchanted by everything luxurious. We only work with brands that share our beliefs and values. That is why we begin our journey together with a free consultation session where we get to know you and your brand. Please fill out the form below with your preferred schedule and we will revert back with a possible appointment.

    </p>
</div>
<div className="banner-call-to-action">

    <a href="https://form.jotform.com/212576608819466" className="request-consulation"> <div >
            Request Consultation
           
            
        </div></a>
</div>
<div className="grey-line"></div>
<div className="brand-consultation">
 <div className="brand-consultation-image contact-image">
<StaticImage className="image-container" src="./images/contact.jpg"/>
 </div>
<div className="brand-consultation-content">
    <div className="banner-heading consulation-heading"> GET IN TOUCH</div>
<div className="contact-content">
Feel free to call or write to us directly for general business enquiries. Please refer to <Link to="/services">our services</Link> for answers to some of your questions. We will revert back to you within 2 business days. If you have any specific questions or concerns about your brands, please mention them in your email. In case you’re interested in working with us, request a consultation above.
<br/>
<p className="contact-call-to-action">
{contactData.buisnessEmail}<br/>
</p>

</div>
</div>
</div>
      
    </Layout>
  );
};

export default ConsultationPage;
